
import {
  computed,
  defineComponent,
  ref,
  unref,
  onBeforeUnmount,
  watch,
} from "vue";
import { SwapOutlined } from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import useStripedRowClass from "@/utils/payment/useStripedRowClass";
import ChianModal from "../../components/chianModal.vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import useProgramChian from "@/hooks/approvalChian/useProgramChian";
import {
  ToActiveApprovalChain,
  ToInactiveApprovalChain,
} from "@/API/approvalChain";
import { useRouter } from "vue-router";
import { DataProps } from "./type";
import downloadFile from "@/utils/claim/downloadFile";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const getRowClass = useStripedRowClass<DataProps>();
    const {
      getChianList,
      ChainUserList,
      ChainRoleList,
      column,
      bu,
      // getBUList,
      // BUList,
    } = useProgramChian();

    // 查询工作流列表
    getChianList();
    watch(bu, () => {
      getChianList();
    });
    const type = ref<string>("user");
    const tableData = computed(() => {
      return type.value === "user"
        ? unref(ChainUserList)
        : unref(ChainRoleList);
    });
    // 切换user和role
    const handleToggleChain = () => {
      type.value = type.value === "user" ? "role" : "user";
    };
    // 修改工作流状态
    const confirm = (status: number, callback: any) => {
      const content =
        status === 1
          ? "Change status to inactive?"
          : "Change status to active?";
      Modal.confirm({
        content,
        cancelText: "No",
        okText: "Yes",
        onOk(close: any) {
          callback(close);
        },
      });
    };
    const handleToggleStatus = (status: number, record: any) => {
      console.log(status, 8888);
      if (status === 0) {
        confirm(0, (close: any) => {
          console.log(record);
          if (record.isFull === 0) {
            Modal.info({
              content: "Current chain exists blank level, cannot be active.",
              onOk() {
                close();
              },
            });
          } else {
            ToActiveApprovalChain(record.businessId).then((res: any) => {
              if (res.code === "1") {
                // message.error(res.message);
                close();
              } else {
                getChianList();
                close();
                message.success("Switch success");
              }
            });
          }
        });
      } else {
        confirm(1, (close: any) => {
          ToInactiveApprovalChain(record.businessId).then((res: any) => {
            if (res.code === "1") {
              // message.error(res.message);
              close();
            } else {
              getChianList();
              close();
              message.success("Switch success");
            }
          });
        });
      }
    };
    // 查询BU字典表
    // getBUList();
    const checkedList = ref([]);
    const handleBuClear = () => {
      checkedList.value = [];
    };
    const chianModalVisible = ref<boolean>(false);
    const chianModalType = ref<string>("new");
    const handleAddNew = () => {
      chianModalVisible.value = true;
      chianModalType.value = "new";
    };
    const handleCopy = () => {
      chianModalVisible.value = true;
      chianModalType.value = "copy";
    };
    const router = useRouter();
    const handleApprovalChainClick = () => {
      router.push({ path: "/approvalChainEdit" });
    };
    // 导出excel
    const handleExport = () => {
      let url = "";
      if (type.value === "user") {
        url = "/apapi/approvalSetting/downloadUserProgramExcel";
      } else {
        url = "/apapi/approvalSetting/downloadRoleProgramExcel";
      }
      const params = {
        url,
        method: "get",
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };

    return {
      getRowClass,
      ChainUserList,
      tableData,
      column,
      handleToggleChain,
      handleToggleStatus,
      // BUList,
      checkedList,
      handleBuClear,
      chianModalVisible,
      chianModalType,
      handleAddNew,
      handleCopy,
      handleApprovalChainClick,
      handleExport,
      bu,
    };
  },
  components: {
    ChianModal,
    TableWrapper,
    SwapOutlined,
  },
});
